<template>
  <div>
    <!-- modal -->
    <b-modal id="modal-update-price" title="Update Price" size="xl" cancel-title="Close" ok-title="Save Changes"
      cancel-variant="outline-secondary" @ok="handlePriceUpdate" @cancel="hideUpdatePriceModal" centered>
      <template #modal-title>
        <b-row class="d-flex align-items-center">
          <b-col cols="4" class="d-flex align-items-center">
            <span>Update Price</span>
          </b-col>
          <b-col cols="4" class="d-flex align-items-center">
            <b-form-checkbox v-model="priceUpdateSettings.discount_active" switch>
              Discount Active
            </b-form-checkbox>
          </b-col>
          <b-col cols="4" class="d-flex align-items-center">
            <b-form-checkbox v-model="priceUpdateSettings.has_date_range" switch
              :disabled="!priceUpdateSettings.discount_active">
              Has Date Range
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" v-if="priceUpdateSettings.has_date_range" class="d-flex align-items-center gap-2">
            <b-form-input v-model="priceUpdateSettings.discount_start_date" type="date" class="form-control-sm" />
            <b-form-input v-model="priceUpdateSettings.discount_end_date" type="date" class="form-control-sm" />
          </b-col>
        </b-row>
      </template>
      <b-card-text>
        <b-table striped hover :items="updatePriceData" :fields="computedPriceUpdateFields">
          <template #cell(color)="data">
            {{ data.item.color ? (typeof data.item.color === 'object' ? data.item.color.color : data.item.color) : '-'
            }}
          </template>

          <template #cell(size)="data">
            {{ data.item.size ? (typeof data.item.size === 'object' ? data.item.size.size : data.item.size) : '-' }}
          </template>

          <template #cell(type)="data">
            {{ data.item.type ? data.item.type.type : '-' }}
          </template>

          <template #cell(price)="data">
            <b-form-input v-model="data.item.price" type="number" min="0" step="0.01" class="form-control-sm" />
          </template>

          <template #cell(discount_price)="data" v-if="priceUpdateSettings.discount_active">
            <b-form-input v-model="data.item.discount_price" type="number" min="0" step="0.01"
              class="form-control-sm" />
          </template>
        </b-table>
      </b-card-text>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">
            Discount Product
          </h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Find Product" />
          </div>
          <b-button variant="primary" class="px-3 mobile-w100" :to="{ name: 'product-create' }">
            <!-- v-if="ability.can('create', 'product_account')" -->
            <span class="text-nowrap"><feather-icon size="14" icon="PlusIcon" /> Product</span>
          </b-button>
        </div>
      </div>

      <b-table ref="refProductListTable" class="position-relative" :items="fetchProducts" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(name)="data">
          <a :href="`${productUrl}product/${data.item.slug}?color=${data.item.product_variant[0].id}`" target="_blank">
            {{ data.item.name }}
          </a>
        </template>

        <template #cell(product_categories)="data">
          <div class="d-flex flex-column">
            <b-badge v-for="(category, index) in data.item.product_categories" :key="`category_${index}`" class="mb-50">
              <a :href="`${productUrl}${category.slug}`" target="_blank">
                {{ category.name }}
              </a>
            </b-badge>
          </div>
        </template>

        <template #cell(cover_image_path)="data">
          <el-image style="
              width: 100%;
              aspect-ratio: 1 /1;
              padding: 0;
              max-width: 140px;
              min-width: 70px;
              border-radius: 5px;
            " fit="cover" :src="`${uploadUrl}${data.item.cover_image_path}`"
            :preview-src-list="[`${uploadUrl}${data.item.cover_image_path}`]" :alt="data.item.alt">
          </el-image>
        </template>

        <template #cell(is_active)="data">
          <b-col cols="12">
            <b-form-checkbox :checked="data.item.is_active ? true : false" @change="updateProductStatus(data.item.id)"
              class="custom-control-success" name="check-button" switch inline />
          </b-col>
        </template>

        <template #cell(product_variant)="row">
          <b-button v-model="row.detailsShowing" plain class="btn-primary" @click="row.toggleDetails">
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table ref="refProductListTable" class="position-relative" :items="row.item.product_variant" responsive
              :fields="tablevariantColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
              empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
              <template #cell(variant_image)="row">
                <div style="
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    gap: 10px;
                  ">
                  <draggable v-model="row.item.variant_image" @start="drag = true" @end="(event) => {
                    drag = false;
                    updateSortOrderForVariantImage(row.item.variant_image);
                  }
                  ">
                    <transition-group>
                      <a v-for="(item, index2) of row.item.variant_image" href="javascript:void(0)" class="drop-item"
                        :key="'docuItem' + item.id + index2" @click="handleDownload(item)">
                        <el-image style="
                            width: 100%;
                            aspect-ratio: 1 /1;
                            padding: 5px;
                            max-width: 140px;
                          " fit="cover" :src="`${uploadUrl}${item.media_path}`" :preview-src-list="row.item.variant_image.map(
                            (x) => uploadUrl + x.media_path
                          )
                            " :alt="item.alt">
                        </el-image>
                      </a>
                    </transition-group>
                  </draggable>
                </div>
              </template>

              <template #cell(is_active)="row">
                <b-col cols="12">
                  <b-form-checkbox :checked="row.item.is_active ? true : false"
                    @change="updateProductVariantStatus(row.item.id)" class="custom-control-success" name="check-button"
                    switch inline />
                </b-col>
              </template>
            </b-table>

            <b-button size="sm" variant="outline-primary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <!-- v-if="ability.can('view', 'product_account')" -->
            <b-dropdown-item :to="{ name: 'product-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <!-- v-if="ability.can('update', 'product_account')" -->
            <b-dropdown-item :to="{ name: 'product-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showUpdatePriceModal(data.item)">
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">Edit Price</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item v-if="data.item.is_company && ability.can('update', 'product_account')" >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Select Birthday Email</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalProducts" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  BModal,
  BFormGroup,
  BForm,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useProductsList from "./useProductsList";
import productStoreModule from "../productStoreModule";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    VBModal,
    BModal,
    BFormGroup,
    BForm,
    BOverlay,
    BFormCheckbox,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      productInfo: { id: 0, password: "", password_confirmation: "" },
      show: false,
      updatePriceData: [],
      priceUpdateFields: [
        { key: 'sku', label: 'SKU' },
        { key: 'color', label: 'Color' },
        { key: 'size', label: 'Size' },
        { key: 'type', label: 'Type' },
        { key: 'stock', label: 'Stock' },
        { key: 'price', label: 'Price' },
        { key: 'discount_price', label: 'Discount Price' },
        { key: 'discount_start_date', label: 'Start Date' },
        { key: 'discount_end_date', label: 'End Date' }
      ],
      priceUpdateSettings: {
        discount_active: false,
        has_date_range: false,
        discount_start_date: null,
        discount_end_date: null,
      },
    };
  },
  computed: {
    computedPriceUpdateFields() {
      let fields = this.priceUpdateFields.filter(field =>
        !['discount_price', 'discount_start_date', 'discount_end_date'].includes(field.key)
      );

      if (this.priceUpdateSettings.discount_active) {
        fields.push({ key: 'discount_price', label: 'Discount Price' });
      }

      return fields;
    }
  },
  methods: {
    showUpdatePriceModal(item) {
      console.log(item);
      this.$bvModal.show("modal-update-price");

      let variants = [];
      if (item.product_variant_type && item.product_variant_type.includes("color") && item.product_variant_type.includes("size")) {
        // Handle color and size variants
        variants = item.product_variant
          .filter(variant => !(variant.parent_variant_id === null && variant.sku === null))
          .map(variant => variant);
      } else {
        // Handle simple variants
        variants = item.product_variant;
      }

      // Check if any variant has discount_price and dates
      const hasDiscount = item.discount_active;
      const hasDateRange = item.has_date_range;

      // Set the switches based on existing data
      this.priceUpdateSettings.discount_active = hasDiscount == 1 ? true : false;
      this.priceUpdateSettings.has_date_range = hasDateRange == 1 ? true : false;

      // Set the date range if available from any variant
      if (hasDateRange) {
        this.priceUpdateSettings.discount_start_date = item.discount_start_date;
        this.priceUpdateSettings.discount_end_date = item.discount_end_date;
      }

      this.updatePriceData = variants;
    },
    handlePriceUpdate(bvModalEvent) {
      // Create payload with both variant data and settings
      const payload = {
        data: this.updatePriceData,
        settings: this.priceUpdateSettings
      };

      store.dispatch('app-product/updateBulkPrice', payload)
        .then(response => {
          this.$swal({
            text: response.data.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          });
          this.hideUpdatePriceModal();
          setTimeout(() => {
            this.refetchData();
          }, 1000);
        })
        .catch(error => {
          this.$swal({
            text: error.response?.data?.message || 'Error updating prices',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          });
        });
    },

    hideUpdatePriceModal() {
      this.$bvModal.hide("modal-update-price");
    },

    updateSortOrderForVariantImage(images) {
      this.loading = true;
      store
        .dispatch("app-product/updateImageOrder", { images: images })
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    updateProductStatus(id) {
      this.loading = true;
      store
        .dispatch("app-product/updateProductStatus", id)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    updateProductVariantStatus(id) {
      this.loading = true;
      store
        .dispatch("app-product/updateProductVariantStatus", id)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },

    onBtnExport() {
      console.log("product export");
      store.dispatch("app-product/fetchProductsExport").then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Products Export List`);
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-product";
    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);
    const productUrl = ref(process.env.VUE_APP_PRODUCT_URL);

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchProducts,
      tableColumns,
      tablevariantColumns,
      tableColorSizeColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,
      ability,
    } = useProductsList();

    return {
      // Sidebar
      fetchProducts,
      tableColumns,
      tablevariantColumns,
      tableColorSizeColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,
      ability,
      uploadUrl,
      productUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.cursor {
  cursor: pointer;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.modal-header {
  .close {
    padding: 0 !important;
    margin: 0 !important;
  }
}
</style>
