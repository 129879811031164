import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/products/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDiscountProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/products/discounted_list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProduct(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/products`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProductStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/products/product_status`, { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProductVariantStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/products/product_variant_status`, { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/products", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteProductDetail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/products/delete_product_detail", { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteImage(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/products/delete_image", { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateImageOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/products/update_sort_order", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateBulkPrice(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/products/update_bulk_price", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
